import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../services/utils/Config';
import { environment } from '../../../../environments/environment';
import { PhoneNumberPipe } from '../../../services/utils/phone-number.pipe';
import { ResponseHandlerService } from '../../../services/utils/response-handler.service';
import { UserManagement } from '../../../services/utils/state-management/action-classes';
import {
  VerificationCallback,
  VerificationService,
} from '../../../services/utils/verification.service';
import { spaceCheck } from '../../../services/utils/utility';
// import { RegisterComponent } from '../registration.component';
// import { UserProfileService } from '../../../services/user';

import { Utility } from '../../../services/utils/utility';
import { UserLoginService } from '../../../services/user-login.service';
import { LoggedInCallback } from '../../../services/utils/cognito.service';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  template: ''
})
export abstract class PhoneVerificationBase
  implements OnInit, LoggedInCallback, VerificationCallback
{
  @ViewChild('ngOtpInput') ngOtpInput;

  environment = environment;
  phoneEntered = true;
  parent;
  confirmedPhone: string;
  errorMessage: string;
  successMessage: string;
  phoneValidation: FormGroup;
  codeValidation: FormGroup;
  maxlength: any;

  invalidNumberMessage: string;
  validPhoneNumber: boolean = true;
  addPhoneUnAuth: boolean = false;
  iti: any;
  dialCode: string = '+1';
  selectedCountryCode: any;
  state: any;
  spaceCheck = spaceCheck;

  //ng-otp-input
  showOtpComponent = true;
  ngconfig = Utility.getNgxOtpConfigObject();

  securityCodePaste: string;
  securityCodeEnter = [];
  securityCode: string;
  securityCondition: boolean = false;
  isLoaderActive: boolean = false;
  isFirstCodeGenerated: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public verificationService: VerificationService,
    public phoneNumberPipe: PhoneNumberPipe,
    public responseHandler: ResponseHandlerService,
    public userManagement: UserManagement,
    public userProfileService: UserProfileService,
    public router: Router,
    public utility: Utility,
    public userService: UserLoginService
  ) {}

  ngOnInit() {

    this.state = JSON.parse(localStorage.getItem('state'));

    this.selectedCountryCode = this.state.updateProfileData && this.state.updateProfileData.countrycode;

    this.phoneEntered = false;

    this.addPhoneUnAuth = window.location.href.includes('login');

    this.phoneValidation = this.formBuilder.group({
      phone: ['', [Validators.required]],
    });
    this.codeValidation = this.formBuilder.group({
      code: [null, [Validators.required]],
    });

    this.maxlength =
      Config.PHONEFORMAT[this.selectedCountryCode].FORMATTEDLENGTH;
    this.userService.isAuthenticated(this);
  }
  abstract onVerify(dialCode);
  abstract onSubmitCode();

  resendCode(dialCode) {
    this.resetOTP();
    this.onVerify(dialCode);
    this.phoneEntered = true;
  }

  onSuccessSendCode(result: any) {
    this.phoneEntered = true;
    this.errorMessage = null;
    this.codeValidation.controls['code'].reset();
    if (this.isFirstCodeGenerated) {
      this.isFirstCodeGenerated = false;
    } else {
      this.successMessage = this.translate.instant(
        'FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS'
      );
    }
  }

  onFailure(messages: any) {
    this.successMessage = null;
    messages = this.responseHandler.getErrorMessage(
      'REGISTRATION.EMAIL_VERIFICATION.ERROR',
      messages
    );
    let errMessg = '';
    this.errorMessage = '';
    if (Array.isArray(messages)) {
      for (errMessg of messages) {
        this.errorMessage += errMessg + ', ';
      }
      this.errorMessage = this.errorMessage.substring(
        0,
        this.errorMessage.length - 2
      );
    } else {
      this.errorMessage = messages;
    }
    if (this.errorMessage.slice(-1) !== '.') {
      this.errorMessage += '.';
    }
  }

  isNotNullOrEmpty(value) {
    if (value == '' || value == null) {
      return true;
    } else {
      return false;
    }
  }

  handleFillEvent(value: string): void {
    this.securityCodePaste = value;
    if (this.securityCodePaste.length === 6 && !this.securityCondition) {
      this.securityCode = this.securityCodePaste;
      this.codeValidation.get('code').setValue(this.securityCode);
      this.isLoaderActive = true;
      this.onSubmitCode();
    }
  }

  public selectedCountry(event) {
    this.dialCode = event.dialCode;
    this.maxlength = event.phoneMaxLength;
  }

  resetOTP() {
    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  callbackWithParam(messages: any) {
    this.successMessage = null;
    messages = this.getErrorMessage(
      'PHONE_VERIFICATION.ERROR.COMMON',
      messages
    );
    let errMessg = '';
    this.errorMessage = '';
    for (errMessg of messages) {
      this.errorMessage += errMessg + ', ';
    }
    this.errorMessage = this.errorMessage.substring(
      0,
      this.errorMessage.length - 2
    );
    if (this.errorMessage.slice(-1) !== '.') {
      this.errorMessage += '.';
    }
  }

  getErrorMessage(componentMessagePath: string, parsedResponse: any) {
    let msg = [];

    if (parsedResponse && parsedResponse.data) {
      const error = parsedResponse.data;
      let dataElement;

      if (error !== null && error.length > 0) {
        for (dataElement of error) {
          if (dataElement) {
            msg.push(
              this.translate.instant(
                componentMessagePath +
                  '.' +
                  dataElement.code.toUpperCase() +
                  '.' +
                  dataElement.target.toUpperCase()
              )
            );
          }
        }
      }
    } else {
      msg.push(this.translate.instant(componentMessagePath + '.COMMON'));
    }
    return msg;
  }

  abstract onSuccess(result: any);

  onLogin() {
    this.userService.logout();
    this.router.navigate(['/home']);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      let user = this.userManagement.getUser();
      let preferredLang = user.preferredLanguage;

      if (preferredLang && preferredLang != null) {
        this.translate.use(preferredLang);
      }
    }
  }
}
